/* You can add global styles to this file, and also import other style files */
:root {
  --color-background-app: #3d3c3c;
  --color-border-input: #4e4486;
  --color-border-success: #00cc60;
  --color-border-warning: #cb9215;
  --color-disabled-input: #ccc;
  --color-font-error: #e32323;
  --color-font-button: #000;
  --color-font-disabled-on-primary: #ababab;
  --color-font: white;
  --color-font-label: #555;
  --color-font-disabled: #bdbdbd;
  --color-font-warning: #eea913;
  --color-primary: #e78626;
  --color-primary-disabled: #858280;
  --color-background-error: tomato;
  --color-background-success: #32813d;
  --color-background-button-hover: #bdbdbd;
}

@font-face {
  font-family: ABeeZee;
  src: url(assets/fonts/ABeeZee/ABeeZee-Regular.ttf) format("truetype");
}
body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: var(--color-background-app);
  font-family: "ABeeZee", sans-serif;
  color: var(--color-font);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.app-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 7px 8px;
  max-height: 84px;
  border-bottom: 1px solid var(--color-primary);
  min-height: 32px;
}
.app-bar img {
  width: 60px;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
}
.actions > :last-child {
  margin-top: 8px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: cornflowerblue;
}